<script setup lang="ts">
    import Avatar from 'primevue/avatar';
    import { type CompanyDto, getAddressPostalLocation } from '@containex/portal-backend-dto';
    import { useI18n } from 'vue-i18n';

    defineProps<{
        userName: string;
        company: CompanyDto | null;
        canChangeCompanies: boolean;
    }>();

    const emit = defineEmits<{
        changeCompany: [];
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="account-info-container">
        <div class="account-info-header">
            <Avatar :label="userName[0]?.toUpperCase()" class="account-info-display-avatar" shape="circle" />
            <div class="flex-container text-base-semibold-line-height-auto">
                {{ userName }}
            </div>
        </div>
        <template v-if="company !== null">
            <div v-if="company?.externalId !== undefined" class="text-md-regular-line-height-auto">
                {{ company.externalId }}
            </div>
            <div class="text-md-regular-line-height-auto">
                {{ company.companyName }}
            </div>
            <div class="text-md-regular-line-height-auto">
                {{ getAddressPostalLocation(company.companyAddress) }}
            </div>
        </template>
        <div
            v-if="canChangeCompanies"
            class="account-info-company-switch text-base-semibold-line-height-auto"
            role="button"
            @click="emit('changeCompany')"
        >
            {{ t('LOGIN.SWITCH') }}
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .account-info-container {
        padding: main.$spacing-5;
    }

    .account-info-header {
        display: flex;
        align-items: center;
        gap: main.$spacing-5;
        margin-bottom: main.$spacing-5;
    }

    .account-info-company-switch {
        padding: main.$spacing-3 0;
        color: main.$color-primary-500;
        cursor: pointer;
    }

    .account-info-display-avatar {
        background-color: main.$color-background-lightblue;
        color: main.$color-primary-500;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
    }
</style>
